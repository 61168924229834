import React from "react";
import './whatwedo-component.css';

interface TileData {
    title: string;
    points: string;
    description: string;
    image: string;
}

interface WhatWeDoProps {
    data: {
        header: string;
        tiles: TileData[];
    };
}

const WhatWeDo: React.FC<WhatWeDoProps> = ({ data }) => {
    return (
        <div className="whatwedo-container">
            <h1 className="whatwedo-header">{data.header}</h1>
            <div className="whatwedo-tiles">
                {data.tiles.map((tile, index) => (
                    <div className="whatwedo-tile" key={index}>
                        <img src={tile.image} alt={tile.title} className="whatwedo-image" />
                        <div className="whatwedo-content">
                            <h2 className="whatwedo-title">{tile.title}</h2>
                            <p className="whatwedo-points">{tile.points}</p>
                            <p className="whatwedo-description">{tile.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhatWeDo;
