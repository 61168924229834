import React from 'react';
import servicesData from '../../Data/services.json';
import WhatWeDoPage from './whatwedo-component';
import './whatwedo-component.css';

const ServicesPage: React.FC = () => {
    return <WhatWeDoPage data={servicesData} />;
};

export default ServicesPage;
