import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header-component.css';

import menuData from '../../Data/menu.json';

interface MenuItem {
  title: string;
  link: string;
  subMenu?: MenuItem[];
}

const Header: React.FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    setMenuItems(menuData.menu);
  }, []);

  console.log(menuItems);

  return (
    <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand company-brand" href="/">
            <img src="/Images/vbrologo.png" alt="VBro Systems" className="company-icon" />
            <div className="company-logo">
              {/* <span className="logo-v">V</span>Bro Systems */}
            </div>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              {menuItems.map((item, index) => (
                <li key={index} className={`nav-item ${item.subMenu ? 'dropdown' : ''}`}>
                  <Link className={`nav-link ${item.subMenu ? 'dropdown-toggle' : ''}`} to={item.link} data-bs-toggle={item.subMenu ? 'dropdown' : undefined}>
                    {item.title}
                  </Link>
                  {item.subMenu && (
                    <ul className="dropdown-menu">
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link className="dropdown-item" to={subItem.link}>
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;