import React, { useState, useEffect } from 'react';
import aboutUsData from '../../Data/aboutus.json';
import './aboutus-component.css';
import ReactMarkdown from 'react-markdown';

interface Section {
  heading: string;
  content: string;
}
interface AboutUsData {
  title: string;
  subtitle: string;
  sections: Section[];
  imageURL?: string;
}

const AboutUs: React.FC = () => {
  const [data, setData] = useState<AboutUsData | null>(null);

  useEffect(() => {
    setData(aboutUsData);
  }, []);

  return (
    <><img src={data?.imageURL} alt="About Us" className="about-us-image" />
      <div className="about-us-container">
        {data && (
          <>
            <header className="about-us-header text-center">
              <h1>{data.title}</h1>
              <p className="subtitle">{data.subtitle}</p>
            </header>
            <main className="about-us-content">
              {data.sections.map((section, index) => (
                <section key={index} className="about-us-section">
                  <h2>{section.heading}</h2>
                  <div dangerouslySetInnerHTML={{ __html: section.content }} />
                </section>
              ))}
            </main>
          </>
        )}
      </div>
    </>
  );
};

export default AboutUs;
