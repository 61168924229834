import React from 'react';
import './reachus-component.css';
import reachUsData from '../../Data/reachus.json';

const ReachUs: React.FC = () => {
    const { imageURL, header, contactDetails, address, socialMedia, map } = reachUsData;

    return (
        <><img src={imageURL} alt="About Us" className="reach-us-image" />
            <div className="reach-us-container">
                <header className="reach-us-header animated-header">
                    <h1>{header.title}</h1>
                    <p>{header.subtitle}</p>
                </header>
                <div className="contact-details animated-contact-details">
                    <div className="contact-info">
                        <h2>Contact Us</h2>
                        <p>Phone: {contactDetails.phone}</p>
                        <p>Email: <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a></p>
                    </div>
                    <div className="address-info">
                        <h2>Our Address</h2>
                        <p>{address.line1}</p>
                        <p>{address.line2}</p>
                        <p>{address.city}, {address.state}, {address.zip}</p>
                    </div>
                </div>
                <div className="social-media-section animated-social-media">
                    <h2>Follow Us</h2>
                    <div className="social-media-icons">
                        {socialMedia.map((media, index) => (
                            <a key={index} href={media.link} target="_blank" rel="noopener noreferrer">
                                <i className={`fab fa-${media.icon}`}></i>
                            </a>
                        ))}
                    </div>
                </div>
                <div className="map-section animated-map">
                    <h2>Find Us Here</h2>
                    <iframe
                        src={map.url}
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Our Location"
                    ></iframe>
                </div>
            </div></>
    );
};

export default ReachUs;
