import React from 'react';
import footerData from '../../Data/footer.json';
import './footer-component.css';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {footerData.sections.map((section, index) => (
                    <div key={index} className="footer-section">
                        <h3>{section.title}</h3>
                        {section.content && <p>{section.content}</p>}
                        {section.links && (
                            <ul>
                                {section.links.map((link, linkIndex) => (
                                    <li key={linkIndex}>
                                        <Link to={link.url}>{link.text}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {section.details && (
                            <ul>
                                {section.details.map((detail, detailIndex) => (
                                    <li key={detailIndex}>
                                        {detail.type === 'phone' && (
                                            <a href={detail.url}>{detail.text}</a>
                                        )}
                                        {detail.type === 'email' && (
                                            <a href={detail.url}>{detail.text}</a>
                                        )}
                                        {detail.type === 'location' && (
                                            <span>{detail.text}</span>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {section.social && (
                            <div className="footer-social">
                                {section.social.map((social, socialIndex) => (
                                    <ul>
                                        <a
                                            key={socialIndex}
                                            href={social.url}
                                            className={`social-icon ${social.platform.toLowerCase()}`}
                                        >
                                            <img src={social.icon} alt={social.platform} className='imageicon' />{social.platform}
                                        </a>
                                    </ul>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="footer-copyright">
                <p>&copy; {new Date().getFullYear()} VBro Systems. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
