import React from 'react';
import cloudData from '../../Data/cloud.json';
import WhatWeDoPage from './whatwedo-component';
import './whatwedo-component.css';

const CloudPage: React.FC = () => {
    return <WhatWeDoPage data={cloudData} />;
};

export default CloudPage;
