import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import Header from './Components/Header/header-component';
import AboutUs from './Components/AboutUs/aboutus-component';
import HomeComponent from './Components/Home/home-component';
import Footer from './Components/Footer/footer-component';
import ReachUs from './Components/ReachUs/reachus-component';
import ServicesPage from './Components/WhatWeDo/services-component';
import SolutionsPage from './Components/WhatWeDo/solutions-component';
import CloudPage from './Components/WhatWeDo/cloud-component';
import DevelopmentPage from './Components/WhatWeDo/development-component';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ReachUs />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/solutions" element={<SolutionsPage />} />
        <Route path="/cloud" element={<CloudPage />} />
        <Route path="/software" element={<DevelopmentPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

