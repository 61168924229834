import React, { useEffect, useRef } from 'react';
import './home-component.css';
import homeData from '../../Data/home.json';
import { Link } from 'react-router-dom';

const HomeComponent: React.FC = () => {
    const { carouselImages, tiles, stats, clients, whyVBro } = homeData;

    const clientCarouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clientCarousel = clientCarouselRef.current;
        if (clientCarousel) {
            const scrollInterval = setInterval(() => {
                if (clientCarousel) {
                    clientCarousel.scrollBy({
                        left: clientCarousel.offsetWidth,
                        behavior: 'smooth'
                    });
                }
            }, 3000); // Adjust the interval as needed

            return () => clearInterval(scrollInterval);
        }
    }, []);

    return (
        <div>
            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {carouselImages.map((image, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <img src={image.url} className="d-block w-100" alt={image.alt} />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>{image.caption}</h5>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <section className="tiles-section">
                <h2 className="section-heading">What We Do</h2>
                <div className="tiles-container">
                    {tiles.map((tile, index) => (
                        <div key={index} className="tile animated-tile">
                            <h3 className="tile-heading">{tile.title}</h3>
                            <p className="tile-description">{tile.description}</p>
                            <p>{tile.points.replace(/\|/g, ' | ')}</p>
                            <Link to={tile.route} className="know-more-button">Know More</Link>
                        </div>
                    ))}
                </div>
            </section>

            <section className="stats-section">
                <h2 className="section-heading">Our Achievements</h2>
                <div className="stats-container">
                    <div className="stat animated-stat">
                        <h3 className="stat-number animated-number">{stats.projects}</h3>
                        <p>Projects Completed</p>
                    </div>
                    <div className="stat animated-stat">
                        <h3 className="stat-number animated-number">{stats.yearsOfExperience}</h3>
                        <p>Years of Experience</p>
                    </div>
                    <div className="stat animated-stat">
                        <h3 className="stat-number animated-number">{stats.teamCount}</h3>
                        <p>Team Members</p>
                    </div>
                </div>
            </section>

            <section className="clients-section">
                <h2 className="section-heading">Our Clients</h2>
                <div className="clients-carousel">
                    <div className="clients-container">
                        {clients.map((client, index) => (
                            <div key={index} className="client animated-client">
                                <img src={client.logo} alt={client.name} />
                                <p>{client.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section className="why-vbro-section">
                <div className="why-vbro-container animated-why-vbro">
                    <div className="why-vbro-center">
                        <h2>{whyVBro.centerText}</h2>
                    </div>
                    <div className="why-vbro-points">
                        {whyVBro.points.map((point, index) => (
                            <div key={index} className="why-vbro-point animated-why-vbro-point">
                                <img src={point.image} alt={point.title} />
                                <h3>{point.title}</h3>
                                <p>{point.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomeComponent;
